<template>
  <div :class="$style.home">
    <BjSearch @refresh="onSearch" @init="init">
      <BjInput
        v-model="search.order_no"
        label="搜索订单"
        v-bind="layout"
        placeholder="请输入订单编号"
        @pressEnter="onSearch"
      />
      <BjInput
        v-model="search.nickname"
        label="搜索用户"
        v-bind="layout"
        placeholder="请输入用户昵称"
        @pressEnter="onSearch"
      />

      <BjValidationItem label="预定场馆" v-bind="layout">
        <a-select
          show-search
          allow-clear
          style="width: 100%"
          :value="search.venue_id"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          placeholder="请输入场馆名称"
          @search="handleSearch"
          @change="handleChange"
        >
          <a-select-option v-for="d in groupData" :key="d.id">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </BjValidationItem>
      <BjSelect
        v-model="search.type"
        v-bind="layout"
        key-field="id"
        label="预订类型"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="typeData"
      />
      <BjSelect
        v-model="search.status"
        v-bind="layout"
        key-field="id"
        label="预订状态"
        inner-search
        placeholder="全部状态"
        value-field="id"
        label-field="name"
        show-all
        :options="statusData"
      />
    </BjSearch>
    <div :class="$style.table">
      <p :class="$style.title">预约列表</p>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #user="item">
          <div :class="$style.cover">
            <img :class="$style.cover" :src="item.user.avatar" />
            <span>{{ item.user.nickname }}</span>
          </div>
        </template>
        <template #name="item">
          {{ item.venue.name }}
        </template>
        <template #status="item">
          <BjTag :type="item.status ? 'primary' : 'default'">{{ item.status === 1 ? '已核销' : '未核销' }}</BjTag>
        </template>
      </a-table>
      <div class="pagination">
        <a-pagination
          :current="page"
          show-size-changer
          :show-total="total => `共 ${total} 条`"
          :page-size.sync="pageSize"
          :total="total"
          @showSizeChange="onShowSizeChange"
          @change="onPageChange"
        />
      </div>
    </div>

    <bj-modal
      :class="$style.modal"
      :width="800"
      title="订单详情"
      :body-style="{ height: '500px', 'max-height': '600px', 'overflow-y': 'auto' }"
      :visible="visible"
      @ok="visible = false"
      @cancel="visible = false"
    >
      <a-tabs v-if="detail.id">
        <a-tab-pane key="1" tab="订单详情">
          <div v-if="detail.id">
            <div :class="$style.box">
              <div :class="$style.left">订单编号</div>
              <div :class="$style.right">{{ detail.order_no }}</div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">预订场馆</div>
              <div :class="$style.right">{{ detail.venue.name }}</div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">预订类型</div>
              <div :class="$style.right">{{ detail.method === 1 ? '预约预订' : '支付预订' }}</div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">预订数量</div>
              <div :class="$style.right">{{ detail.count }}</div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">预订信息</div>
              <div :class="$style.right">
                <div>预订日期 : {{ detail.date }}</div>
                <div v-for="(item, index) in detail.sku.specs" :key="index" class="mt-10">
                  {{ item.name }} : {{ item.values[0].value }}
                </div>
              </div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">联系电话</div>
              <div :class="$style.right">{{ detail.contact }}</div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">支付金额</div>
              <div :class="$style.right">￥ {{ detail.paid }}</div>
            </div>
            <div :class="$style.box">
              <div :class="$style.left">订单时间</div>
              <div :class="$style.right">{{ detail.created_at }}</div>
            </div>
          </div>
          <div :class="$style.line" />
          <div :class="$style.box">
            <div :class="$style.left">预订用户</div>
            <div :class="$style.right">
              <img :class="$style.cover" :src="detail.user.avatar" />
              <span class="primary">{{ detail.user.nickname }}</span>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="出行用户">
          <a-table :columns="detailColumns" :data-source="detail.sn_codes" :pagination="false" row-key="id">
            <template #user="item">
              <div :class="$style.cover">
                <span>{{ item.user.nickname }}</span>
              </div>
            </template>
            <template #state="item">
              <BjTag :type="item.status_name === '未核销' ? 'primary' : 'default'">{{ item.status_name }}</BjTag>
            </template>
            <template #action="item">
              <bj-link :disabled="item.status_name !== '未核销'" type="primary" @click="onWrite(item)">
                立即核销
              </bj-link>
            </template>
          </a-table>
        </a-tab-pane>
      </a-tabs>
    </bj-modal>
  </div>
</template>

<script>
import { appVenueService } from '@/service'

const service = new appVenueService()

export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      data: [],
      groupData: [],
      id: null,
      visible: false,
      search: {
        nickname: null,
        order_no: null,
        status: '',
        type: '',
        venue_id: [],
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      detail: {},
      page: 1,
      pageSize: 20,
      total: 0,
      statusData: [
        {
          name: '全部状态',
          id: '',
        },
        {
          name: '未核销',
          id: 0,
        },
        {
          name: '部分核销',
          id: 1,
        },
        {
          name: '全部核销',
          id: 2,
        },
        {
          name: '已退订',
          id: 10,
        },
      ],
      typeData: [
        {
          name: '全部类型',
          id: '',
        },
        {
          name: '门票预订',
          id: 1,
        },
        {
          name: '场馆预订',
          id: 2,
        },
        {
          name: '自定义预订',
          id: 3,
        },
      ],
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '订单编号',
          dataIndex: 'order_no',
        },
        {
          title: '提交用户',
          scopedSlots: {
            customRender: 'user',
          },
        },
        {
          title: '预订场馆',
          scopedSlots: {
            customRender: 'name',
          },
        },
        {
          title: '预订类型',
          customRender: item => (item.type === 1 ? '门票预定' : item.type === 2 ? '场馆预定' : '自定义预定'),
        },
        {
          title: '预订条目',
          customRender: item => item.sku.item.name,
        },
        { title: '订单时间', dataIndex: 'created_at' },
        {
          title: '预订状态',
          dataIndex: 'status_name',
        },
        {
          title: '管理',
          width: 150,
          customRender: item => [
            <bj-link type='primary' onClick={() => this.onDetail(item.id)}>
              详情
            </bj-link>,
            <bj-link type='primary' onClick={() => this.onRecovery(item.id)}>
              退订
            </bj-link>,
            <a-popconfirm title='确定要删除吗？' onConfirm={() => this.onDelete(item.id)}>
              <bj-link type='danger'>删除</bj-link>
            </a-popconfirm>,
          ],
        },
      ]
    },
    detailColumns() {
      return [
        {
          title: '用户姓名',
          dataIndex: 'name',
        },
        {
          title: '身份证号码',
          dataIndex: 'id_card',
        },
        {
          title: 'SN码',
          dataIndex: 'sn_code',
        },
        {
          title: '核销状态',
          scopedSlots: {
            customRender: 'state',
          },
        },
        {
          title: '管理',
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  created() {
    this.getList()
  },
  methods: {
    async handleSearch(value) {
      const { data } = await service.getList({
        name: value,
        page: 1,
        page_size: 10,
      })
      this.groupData = data.record
    },
    handleChange(value) {
      this.search.venue_id = value
    },
    async getDetail() {
      const { data } = await service.getAppointmentDetail({
        id: this.id,
      })
      this.detail = data
    },
    async getList() {
      try {
        this.loading = true
        const { data } = await service.getManageAppList({
          ...this.search,
          venue_id: this.search.venue_id === [] ? '' : this.search.venue_id,
          page: this.page,
          page_size: this.pageSize,
        })
        this.data = data.record
        this.total = data.total
        this.loading = false
      } catch (error) {
        this.loading = false
      }
    },
    onSearch() {
      this.page = 1
      this.getList()
    },
    onDetail(_id) {
      this.id = _id
      this.visible = true
      this.getDetail()
    },
    init() {
      this.search = {
        nickname: null,
        order_no: null,
        status: '',
        type: '',
        venue_id: [],
      }
      this.onSearch()
    },
    onPageChange(page) {
      this.page = page
      this.getList()
    },
    onShowSizeChange() {
      this.page = 1
      this.getList()
    },
    async onWrite(item) {
      try {
        await service.write_off({
          sn_code: item.sn_code,
        })
        this.getDetail()
        this.$message.success('操作成功')
      } catch (e) {}
    },
    async onDelete(_id) {
      try {
        await service.deleteAppointment({
          appointment_id: _id,
        })
        this.page = 1
        this.$message.success('删除成功')
        this.getList()
      } catch (e) {}
    },
    async onRecovery(id) {
      try {
        await service.cancel({
          appointment_id: id,
        })
        this.getList()
        this.$message.success('操作成功')
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  .table {
    background: #fff;
    padding: 20px;
    margin-top: 20px;

    .title {
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;
    }

    .cover {
      display: flex;
      align-items: center;
      color: @primary-color;
      font-size: 13px;

      img {
        border-radius: 100%;
        width: 32px;
        height: 32px;
        object-fit: cover;
        margin-right: 10px;
      }
    }
  }
}

.modal {
  .box {
    display: flex;
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;

    .left {
      width: 100px;
    }

    .right {
      flex: 1;

      .cover {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        object-fit: cover;
        margin-right: 10px;
      }
    }
  }

  .line {
    width: 100%;
    border-top: 1px solid #eee;
    margin: 20px 0 20px 0;
  }
}
</style>
